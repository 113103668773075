@import "./themes/generated/variables.base.scss";

.app {
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

.dzu-dropzone {
  border-style: dashed;
  border-color: #0079a7;
  overflow: auto;
}

.dzu-inputLabel {
  text-align: center;
  color: $base-accent;
  font-size: 18px;
}

.dzu-inputLabelWithFiles {
  margin-bottom: 10px !important;
  background-color: $base-accent !important;
  color: #fff !important;
}

.dx-button.dx-button-success {
  background-color: rgb(22 163 74 / 1) !important;
}

.dx-button.dx-button-success:hover {
  background-color: rgb(26, 138, 67) !important;
}

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

@media (min-width: 1280px) {
  .container {
    max-width: 100% !important;
  }
}

.dx-scrollable-content > ul:first-child > li:last-child {
  margin-bottom: 35% !important;
  border-bottom: none !important;
}

.editor {
  font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial,
    sans-serif !important;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #0000;
  overflow: auto;
  padding: 0 var(--ck-spacing-standard);
  min-height: 100px !important;
  font-family: Roboto, RobotoFallback, "Noto Kufi Arabic", Helvetica, Arial,
    sans-serif !important;
}
